import { createTheme } from '@mui/material/styles';
import _merge from 'lodash/merge';

import { components } from './components';
import { colorsLight, colorsDark } from './colors';
import { typography } from './typography';

export const customizableLightTheme = (customization) => {
  const customizedLightThemeBase = createTheme(
    _merge(
      {},
      typography(customization?.typography ?? {}),
      colorsLight(customization?.palette?.primary?.main)
    )
  );

  return createTheme(
    _merge({}, customizedLightThemeBase, components(customizedLightThemeBase), customization)
  );
};

export const customizableDarkTheme = (customization) => {
  const customizedDarkThemeBase = createTheme(
    _merge(
      {},
      typography(customization?.typography ?? {}),
      colorsDark(customization?.palette?.primary?.main, customization?.palette?.darker)
    )
  );

  return createTheme(
    _merge({}, customizedDarkThemeBase, components(customizedDarkThemeBase), customization)
  );
};

const themes = {
  light: customizableLightTheme,
  dark: customizableDarkTheme,
};
export default themes;
