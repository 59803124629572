import React, { useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import themes from '../theme';
import Reports from './Reports';
import '../assets/styles/fonts.css';
import Parties from './Parties';
import Orders from './orders/Orders';
import RoutePaths from '../configs/Routes';
import NotFound from './NotFound';
import Settings from './Settings';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import Products from './Products';
import Collections from './Collections';
import '../assets/styles/global.css';
import { ToastrProvider } from '../contexts/ToastrContext';
import { validateLoggedInUser } from '../services/Auth';
import Logout from './auth/Logout';
import Ledger from './Ledger';
import OrderForm from './orders/OrderForm';
import OrderDetails from './orders/OrderDetails';
import AppSheetSyncLogs from './AppSheetSyncLogs';

const App = () => {
  useEffect(async () => {
    try {
      const result = await validateLoggedInUser();
      if (result.success) {
        window.localStorage.setItem('isLoggedIn', true);
      } else {
        // DO SOMETHING
      }
    } catch {
      // DO SOMETHING
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes.light()}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CssBaseline />
          <ToastrProvider>
            <Router>
              <Switch>
                <Route exact path={RoutePaths.LOGIN} component={Login} />
                <Route exact path={RoutePaths.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route exact path={RoutePaths.LOGOUT} component={Logout} />
                <Route exact path={RoutePaths.REPORTS} component={Reports} />
                <Route exact path={RoutePaths.PARTIES} component={Parties} />
                <Route exact path={RoutePaths.ORDERS} component={Orders} />
                <Route exact path={RoutePaths.CREATE_ORDER} component={OrderForm} />
                <Route exact path={RoutePaths.EDIT_ORDER} component={OrderForm} />
                <Route exact path={RoutePaths.ORDER_DETAILS} component={OrderDetails} />
                <Route exact path={RoutePaths.SYNC_LOGS} component={AppSheetSyncLogs} />
                <Route exact path={RoutePaths.PRODUCTS} component={Products} />
                <Route exact path={RoutePaths.COLLECTIONS} component={Collections} />
                <Route exact path={RoutePaths.LEDGER} component={Ledger} />
                <Route exact path={RoutePaths.SETTINGS} component={Settings} />
                <Route exact path="*" component={NotFound} />
              </Switch>
            </Router>
          </ToastrProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
