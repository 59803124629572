// Application routes
const RoutePaths = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  REPORTS: '/',
  ORDERS: '/orders',
  ORDER_DETAILS: '/orders/:id',
  CREATE_ORDER: '/orders/create',
  EDIT_ORDER: '/orders/edit/:id',
  SYNC_LOGS: '/sync-logs/',
  PRODUCTS: '/products',
  PARTIES: '/parties',
  COLLECTIONS: '/collections',
  LEDGER: '/ledger',
  SETTINGS: '/settings',
};

export default RoutePaths;
