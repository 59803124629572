import { FALLBACK_BOXES } from '../configs/Constants';

export const getBoxesList = () => {
  try {
    const getBoxesFromLocal = localStorage.getItem('boxes_options');

    if (getBoxesFromLocal) {
      const lBList = getBoxesFromLocal.split(',').map((b) => Number(b));
      return { data: lBList };
    }
    localStorage.setItem('boxes_options', FALLBACK_BOXES);
    const fBList = FALLBACK_BOXES.split(',').map((b) => Number(b));
    return { data: fBList };
  } catch (e) {
    localStorage.setItem('boxes_options', FALLBACK_BOXES);
    const fBList = FALLBACK_BOXES.split(',').map((b) => Number(b));
    return { data: fBList };
  }
};

export const saveBoxesValue = (boxes) => {
  const bArr = boxes.split(',').map((e) => Number(e));
  let isValid = true;
  bArr.forEach((v) => {
    if (!v) {
      isValid = false;
    }
  });
  localStorage.setItem('boxes_options', boxes);
  return isValid;
};
export const getBoxesValue = () => {
  try {
    const getBoxesFromLocal = localStorage.getItem('boxes_options');

    if (getBoxesFromLocal) {
      getBoxesFromLocal.split(',').map((b) => Number(b));
      return {
        id: 's_boxes',
        settingName: 'Boxes',
        settingValue: getBoxesFromLocal,
        settingKey: 'boxes_options',
      };
    }

    localStorage.setItem('boxes_options', FALLBACK_BOXES);
    FALLBACK_BOXES.split(',').map((b) => Number(b));
    return {
      id: 's_boxes',
      settingName: 'Boxes',
      settingValue: FALLBACK_BOXES,
      settingKey: 'boxes_options',
    };
  } catch (e) {
    localStorage.setItem('boxes_options', FALLBACK_BOXES);
    FALLBACK_BOXES.split(',').map((b) => Number(b));
    return {
      id: 's_boxes',
      settingName: 'Boxes',
      settingValue: FALLBACK_BOXES,
      settingKey: 'boxes_options',
    };
  }
};

// Settings Other APIs
