import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CircularProgress, Typography } from '@mui/material';
import PrivateWrapper from '../layouts/Private';
import { INR } from '../configs/Constants';
import { getOverAllReports } from '../services/Report';
import useToastr from '../hooks/useToastr';

const Dashboard = () => {
  const { showErrorToastr } = useToastr();

  const [dataLoading, setDataLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [refreshGrid, setRefreshGrid] = useState(false);

  const [openingBalance, setOpeningBalance] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [netBalance, setNetBalance] = useState(0);

  const [totalOrdersAmount, setTotalOrdersAmount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [totalCollectionAmount, setTotalCollectionAmount] = useState(0);
  const [collectionCount, setCollectionCount] = useState(0);

  const [totalParties, setTotalParties] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    setDataLoading(true);

    getOverAllReports()
      .then((result) => {
        if (result.success) {
          const { data } = result;

          setOpeningBalance(data?.ledgerReport?.openingBalance || 0);
          setNetBalance(data?.ledgerReport?.totalBalance || 0);
          setTotalDebit(data?.ledgerReport?.totalDebit || 0);
          setTotalCredit(data?.ledgerReport?.totalCredit || 0);

          setTotalOrdersAmount(data?.ordersReport?.totalOrdersAmount || 0);
          setOrdersCount(data?.ordersReport?.ordersCount);
          setTotalCollectionAmount(data?.collectionsReport?.totalCollectionAmount || 0);
          setCollectionCount(data?.collectionsReport?.collectionCount || 0);

          setTotalParties(data?.partiesReport?.totalParties || 0);
          setTotalProducts(data?.productsReport?.totalProducts || 0);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );

        setDataLoading(false);
      });
  }, [refreshGrid]);

  return (
    <PrivateWrapper pageName="Reports ">
      {dataLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '90vh' }}>
          <CircularProgress size={32} />
        </Box>
      ) : (
        <Box sx={{ my: 4 }}>
          <Box sx={{ border: '1px solid rgba(225, 224, 226, 1)', my: 1, p: 1, borderRadius: 1 }}>
            <Grid container>
              <Grid item justifySelf="center" xs={12} sm={6} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    borderRight: {
                      md: '1px solid rgba(225, 224, 226, 1)',
                      sm: '1px solid rgba(225, 224, 226, 1)',
                    },
                    p: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Opening Balance
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 1,
                      fontWeight: '600',
                      color: (theme) => (openingBalance > 0 ? theme.palette.success.light : 'red'),
                    }}
                  >
                    {INR.format(openingBalance)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ borderRight: { md: '1px solid rgba(225, 224, 226, 1)' }, p: 1 }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Total Debit(-)
                  </Typography>
                  <Typography variant="h5" sx={{ mt: 1, fontWeight: '600', color: 'red' }}>
                    {INR.format(totalDebit)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    borderRight: {
                      md: '1px solid rgba(225, 224, 226, 1)',
                      sm: '1px solid rgba(225, 224, 226, 1)',
                    },
                    p: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Total Credit(+)
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ mt: 1, fontWeight: '600', color: (theme) => theme.palette.success.light }}
                  >
                    {INR.format(totalCredit)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" flexDirection="column" sx={{ p: 1 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Net Balance
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 1,
                      fontWeight: '600',
                      color: (theme) => (netBalance > 0 ? theme.palette.success.light : 'red'),
                    }}
                  >
                    {INR.format(netBalance)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ border: '1px solid rgba(225, 224, 226, 1)', my: 1, p: 1, borderRadius: 1 }}>
            <Grid container>
              <Grid item justifySelf="center" xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    borderRight: {
                      md: '1px solid rgba(225, 224, 226, 1)',
                      sm: '1px solid rgba(225, 224, 226, 1)',
                    },
                    p: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Total Sales
                  </Typography>
                  <Typography variant="h5" sx={{ mt: 1, fontWeight: '600', color: 'primary.main' }}>
                    {INR.format(totalOrdersAmount)}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      mt: 1,
                      color: 'text.secondary',
                      fontWeight: 'bolder',
                    }}
                  >
                    From a total of {ordersCount} Orders
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" sx={{ p: 1 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Total Payments
                  </Typography>
                  <Typography variant="h5" sx={{ mt: 1, fontWeight: '600', color: 'primary.main' }}>
                    {INR.format(totalCollectionAmount)}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      mt: 1,
                      color: 'text.secondary',
                      fontWeight: 'bolder',
                    }}
                  >
                    From a total of {collectionCount} Payments
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ border: '1px solid rgba(225, 224, 226, 1)', my: 1, p: 1, borderRadius: 1 }}>
            <Grid container>
              <Grid item justifySelf="center" xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    borderRight: {
                      md: '1px solid rgba(225, 224, 226, 1)',
                      sm: '1px solid rgba(225, 224, 226, 1)',
                    },
                    p: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Parties
                  </Typography>
                  <Typography variant="h5" sx={{ mt: 1, fontWeight: '600', color: 'primary.main' }}>
                    {totalParties}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" sx={{ p: 1 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '600',
                    }}
                  >
                    Products
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 1,
                      fontWeight: '600',
                      color: 'primary.main',
                    }}
                  >
                    {totalProducts}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </PrivateWrapper>
  );
};

export default Dashboard;
