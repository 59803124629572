import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import enInLocale from 'date-fns/locale/en-IN';
import useStyles from '../theme/styles/views/Orders';
import PrivateWrapper from '../layouts/Private';
import { DT_PAGE_SIZE, INR } from '../configs/Constants';
import { deleteCollectionById, getCollections } from '../services/Collection';
import ConfirmDialog from '../components/common/ConfirmDialog';
import ManageCollectionForm from '../components/collections/ManageCollectionForm';
import useToastr from '../hooks/useToastr';

const Collections = () => {
  const classes = useStyles();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  const [selectedCollection, setSelectedCollection] = useState(null);
  const [openCollectionForm, setOpenCollectionForm] = useState(false);
  const [openDeleteCollectionDialog, setOpenDeleteCollectionDialog] = useState(false);

  const onDelete = (id) => {
    setSelectedCollection(id);
    setOpenDeleteCollectionDialog(true);
  };
  const onEdit = (id) => {
    setSelectedCollection(id);
    setOpenCollectionForm(true);
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      flex: 1,
      minWidth: 60,
    },
    {
      headerName: 'Party',
      field: 'name',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      valueGetter: (prms) => INR.format(prms?.row?.amount || 0),
      type: 'number',
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: 'Payment Mode',
      field: 'paymentMode',
      minWidth: 150,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => value && format(new Date(value), 'P', { locale: enInLocale }),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      textAlign: 'right',
      type: 'actions',
      flex: 1,
      minWidth: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon fontSize="small" color="secondary" />}
          label="View"
          onClick={() => {
            onEdit(params.id);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon fontSize="small" color="error" />}
          label="Delete"
          onClick={() => {
            onDelete(params.id);
          }}
        />,
      ],
    },
  ];

  const deleteCollection = () => {
    deleteCollectionById(selectedCollection)
      .then((result) => {
        if (result.success) {
          showSuccessToastr('Collection deleted successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setOpenDeleteCollectionDialog(false);
        setSelectedCollection(null);
        setRefreshGrid(!refreshGrid);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setOpenDeleteCollectionDialog(false);
        setSelectedCollection(null);
      });
  };

  useEffect(() => {
    setDataLoading(true);
    const skip = currentPage === 0 ? 0 : currentPage * DT_PAGE_SIZE;
    getCollections(skip)
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setRows(data.collections.map((c) => ({ ...c, name: c.party.name })));
          setTotalRecords(data.totalRecords);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setRows([]);
        setDataLoading(false);
      });
  }, [currentPage, refreshGrid]);

  return (
    <PrivateWrapper pageName="Collections">
      <Box display="flex" flexDirection="column">
        <div className={classes.filterLeft}>
          <div />
          <div className={classes.moveRight}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.addNewBtn}
              onClick={() => {
                setOpenCollectionForm(true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>

        <div style={{ height: '80vh', width: '100%' }}>
          <DataGrid
            dataLoading={dataLoading}
            rows={rows}
            rowCount={totalRecords}
            columns={columns}
            pageSize={DT_PAGE_SIZE}
            page={currentPage}
            paginationMode="server"
            onPageChange={setCurrentPage}
          />
        </div>
      </Box>
      {openCollectionForm && (
        <ManageCollectionForm
          editId={selectedCollection}
          title="Create Collection"
          isOpen={openCollectionForm}
          onSave={() => {
            setOpenCollectionForm(false);
            setSelectedCollection(null);
            setRefreshGrid(!refreshGrid);
          }}
          onClose={() => {
            setOpenCollectionForm(false);
            setSelectedCollection(null);
          }}
        />
      )}
      {openDeleteCollectionDialog && (
        <ConfirmDialog
          title="Are you sure want to delete this collection log?"
          isOpen={openDeleteCollectionDialog}
          onConfirm={deleteCollection}
          onClose={() => {
            setOpenDeleteCollectionDialog(false);
            setSelectedCollection(null);
          }}
        />
      )}
    </PrivateWrapper>
  );
};

export default Collections;
