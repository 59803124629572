import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

export const getCollections = async (skip) => {
  const result = await getApiCall(`/collections?skip=${skip || 0}`);
  return result.data;
};

export const getCollectionById = async (collectionId) => {
  const result = await getApiCall(`/collections/${collectionId}`);
  return result.data;
};

export const createCollection = async (collectionData) => {
  const result = await postApiCall(`/collections`, collectionData);
  return result.data;
};

export const updateCollectionById = async (collectionId, collectionData) => {
  const result = await postApiCall(`/collections/${collectionId}`, collectionData);
  return result.data;
};

export const deleteCollectionById = async (collectionId) => {
  const result = await deleteApiCall(`/collections/${collectionId}`);
  return result.data;
};
