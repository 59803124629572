import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

export const getParties = async ({ skip, searchString }) => {
  const result = await getApiCall(`/parties?skip=${skip || 0}&searchString=${searchString || ''}`);
  return result.data;
};

export const getPartyById = async ({ partyId, skipOrder }) => {
  const result = await getApiCall(`/parties/${partyId}/?skip=${skipOrder}`);
  return result.data;
};

export const createParty = async (partyData) => {
  const result = await postApiCall(`/parties`, partyData);
  return result.data;
};

export const updatePartyById = async (partyId, partyData) => {
  const result = await postApiCall(`/parties/${partyId}`, partyData);
  return result.data;
};

export const deletePartyById = async (partyId) => {
  const result = await deleteApiCall(`/parties/${partyId}`);
  return result.data;
};
