export const NAV_DRAWER_WIDTH = 256;
export const APP_BAR_HEIGHT = 56;

export const DT_PAGE_SIZE = 15;

export const FALLBACK_BOXES = '10,11,12,13,14,15,16,17,18,19,20';

export const DataStatus = {
  1: 'Active',
  Active: 1,
  ACTIVE: 1,
  2: 'Inactive',
  Inactive: 2,
  INACTIVE: 2,
  3: 'Deleted',
  Deleted: 3,
  DELETED: 3,
};

export const UserTypes = {
  1: 'Admin',
  Admin: 1,
  ADMIN: 1,
  2: 'Manager',
  Manager: 2,
  MANAGER: 2,
  3: 'Employee',
  Employee: 3,
  EMPLOYEE: 3,
};

export const BoxType = {
  KG_5: {
    value: 5,
    text: '5 KG',
  },
  KG_12: {
    value: 12,
    text: '12 KG',
  },
  KG_15: {
    value: 15,
    text: '15 KG',
  },
  KG_18: {
    value: 18,
    text: '18 KG',
  },
};

export const PackagingType = {
  GM_100: {
    value: 0.2,
    text: '200 gm',
    rate: 100,
  },
  KG_1: {
    value: 1,
    text: '1 KG',
    rate: 400,
  },
};

export const INR = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

export const SYNC_MODE = {
  ADD: 'Add',
  UPDATE: 'Update',
  DELETE: 'Delete',
  SPREADSHEET_SYNC: 'Spreadsheet',
  1: 'ADD',
  2: 'UPDATE',
  0: 'DELETE',
  3: 'SPREADSHEET SYNC',
};

export const SYNC_MODE_VALUES = {
  Add: 1,
  Update: 2,
  Delete: 0,
  Spreadsheet: 3,
};

export const SYNC_STATUS = {
  SUCCESS: 1,
  FAILED: 0,
  1: 'Success',
  0: 'Failed',
};
