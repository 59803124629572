import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import Validations from '../../utils/Validations';
import useToastr from '../../hooks/useToastr';

const AppSettingsForm = ({ onClose, settingKey, settingValue, settingName, onSave }) => {
  const { control, handleSubmit } = useForm();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const onSubmit = async (data) => {
    try {
      localStorage.setItem(settingKey, data.settingValue);
      showSuccessToastr('Settings updated successfully.');
      onSave();
    } catch (error) {
      showErrorToastr(error?.message || 'Something went wrong.');
      onClose();
    }
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Edit - {settingName}</DialogTitle>
      <DialogContent>
        <form id="manage-product" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column">
            <Controller
              control={control}
              id="settingValue"
              name="settingValue"
              rules={{ ...Validations.REQUIRED }}
              defaultValue={settingValue}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="Value"
                  variant="outlined"
                  focused
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="manage-product" autoFocus color="primary" variant="contained">
          Save
        </Button>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppSettingsForm;

AppSettingsForm.propTypes = {
  settingName: PropTypes.string.isRequired,
  settingKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  settingValue: PropTypes.string.isRequired,
};
