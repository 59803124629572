import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/lab';
import Validations from '../../utils/Validations';
import {
  createCollection,
  getCollectionById,
  updateCollectionById,
} from '../../services/Collection';
import useToastr from '../../hooks/useToastr';
import { getParties } from '../../services/Party';

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 35001,
  }),
  container: (st) => ({ ...st, width: '100%', marginTop: 8 }),
};

const ManageCollectionForm = ({ title, onClose, onSave, editId }) => {
  const { control, handleSubmit, setValue, getValues } = useForm();
  const { showErrorToastr, showSuccessToastr } = useToastr();
  const [processing, setProcessing] = useState(false);
  const [collectionDate, setCollectionDate] = useState(new Date());

  const loadParties = (searchString, callback) => {
    getParties({ skip: 0, searchString }).then((res) => {
      callback(
        res.data.parties.map((party) => ({
          name: party.name,
          partyId: party.id,
        }))
      );
    });
  };

  const onSubmit = async (data) => {
    try {
      setProcessing(true);

      if (!collectionDate) {
        showErrorToastr('Please enter valid collection date.');
        setProcessing(false);
        return;
      }

      const payload = {
        partyId: data.party.partyId,
        amount: Number(data.amount),
        paymentMode: data.paymentMode,
        createdAt: collectionDate.toLocaleDateString(),
      };

      if (editId) {
        const result = await updateCollectionById(editId, payload);
        if (result.success) {
          showSuccessToastr('Collection updated successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        onSave();
      } else {
        const result = await createCollection(payload);
        if (result.success) {
          showSuccessToastr('Collection created successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        onSave();
      }
      setProcessing(false);
    } catch (error) {
      showErrorToastr(error?.response?.data?.message || error?.message || 'Something went wrong.');
      setProcessing(false);
      onClose();
    }
  };

  useEffect(() => {
    if (editId) {
      getCollectionById(editId)
        .then((result) => {
          if (result.success) {
            const { data } = result;
            setValue('party', { partyId: data.partyId, name: data.party.name });
            setValue('amount', data.amount);
            setCollectionDate(data.createdAt);
            setValue('paymentMode', data.paymentMode);
          }
        })
        .catch((error) => {
          showErrorToastr(
            error?.response?.data?.message || error?.message || 'Something went wrong.'
          );
          onClose();
        });
    }
  }, [editId]);

  const defaultValues = getValues();
  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id="manage-party" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            id="party"
            name="party"
            defaultValue={defaultValues.party}
            rules={{ ...Validations.REQUIRED }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  menuPortalTarget={document.querySelector('#dactions')}
                  value={value}
                  styles={customStyles}
                  defaultValue={defaultValues.party}
                  getOptionLabel={(optn) => optn.name}
                  getOptionValue={(optn) => optn.partyId}
                  loadOptions={loadParties}
                  onChange={onChange}
                  placeholder="Party"
                />
                {error && <Typography color="error">{error.message}</Typography>}
              </div>
            )}
          />
          <DesktopDatePicker
            label="Date"
            inputFormat="dd/MM/yyyy"
            value={collectionDate}
            onChange={setCollectionDate}
            renderInput={(params) => (
              <TextField required sx={{ mt: 2 }} fullWidth variant="outlined" {...params} />
            )}
          />
          <Box display="flex" flexDirection="column">
            <Controller
              control={control}
              id="amount"
              name="amount"
              rules={{ ...Validations.REQUIRED }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="Amount"
                  variant="outlined"
                  sx={{ mt: 2 }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
            <Controller
              control={control}
              id="paymentMode"
              name="paymentMode"
              rules={Validations.REQUIRED}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="Payment Mode"
                  select
                  variant="outlined"
                  sx={{ mt: 2 }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error?.message : null}
                >
                  <MenuItem value="U">U</MenuItem>
                  <MenuItem value="OBS">OBS</MenuItem>
                  <MenuItem value="OBP">OBP</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="K">K</MenuItem>
                  <MenuItem value="UPI">UPI</MenuItem>
                </TextField>
              )}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="manage-party"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageCollectionForm;

ManageCollectionForm.propTypes = {
  editId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
