import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import pageStyle from '../../theme/styles/components/orders/OrderForm';
import PrivateWrapper from '../../layouts/Private';
import Validations from '../../utils/Validations';
import { INR } from '../../configs/Constants';
import { createOrder, getOrderById, updateOrderById } from '../../services/Order';
import { getParties } from '../../services/Party';
import { getProducts } from '../../services/Product';
import { getBoxesList } from '../../services/Settings';
import useToastr from '../../hooks/useToastr';
import RoutePaths from '../../configs/Routes';

const useStyles = makeStyles(pageStyle);

const OrderForm = ({ match }) => {
  const editId = Number(match.params.id) || 0;
  const pageName = editId ? `Edit ODR - ${editId}` : 'Create Order';

  const classes = useStyles();
  const history = useHistory();
  const { control, handleSubmit, getValues, watch, setValue } = useForm();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [productOptions, setProductOptions] = useState([]);
  const [boxesOptions, setBoxesOptions] = useState([]);

  const [dataLoading, setDataLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [orderDate, setOrderDate] = useState(new Date());
  const [netAmount, setNetAmount] = useState(0);
  const [netWeight, setNetWeight] = useState(0);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const handleAddItem = () => {
    append({
      name: { label: productOptions[0].name, rate: productOptions[0].rate },
      boxType: boxesOptions[0],
      quantity: 1,
      rate: productOptions[0].rate,
      weight: boxesOptions[0] * 1,
      total: boxesOptions[0] * 1 * productOptions[0].rate,
    });
  };

  const loadParties = (searchString, callback) => {
    getParties({ skip: 0, searchString }).then((res) => {
      callback(
        res.data.parties.map((party) => ({
          name: party.name,
          partyId: party.id,
        }))
      );
    });
  };

  const onSubmit = (data) => {
    setProcessing(true);
    if (!orderDate) {
      showErrorToastr('Please enter valid order date.');
      setProcessing(false);
      return;
    }

    const orderData = {
      partyId: data.party.partyId,
      netWeight,
      netAmount,
      createdAt: orderDate.toLocaleDateString(),
      itemsCount: data.items.length,
      orderItems: data.items.map((d) => ({
        name: d?.name?.label || '',
        boxType: Number(d.boxType),
        quantity: Number(d.quantity),
        weight: Number(d.weight),
        rate: Number(d.rate),
        total: Number(d.total),
        partyId: data.party.partyId,
      })),
    };

    if (editId) {
      updateOrderById(editId, orderData)
        .then((res) => {
          if (res.success) {
            showSuccessToastr('Order updated successfully.');
            history.replace(RoutePaths.ORDERS);
          } else {
            showErrorToastr(res?.data?.message || res?.message || 'Something went wrong.');
          }
          setProcessing(false);
        })
        .catch((error) => {
          showErrorToastr(
            error?.response?.data?.message || error?.message || 'Something went wrong.'
          );
          setProcessing(false);
        });
    } else {
      createOrder(orderData)
        .then((res) => {
          if (res.success) {
            showSuccessToastr('Order created successfully.');
            history.replace(RoutePaths.ORDERS);
          } else {
            showErrorToastr(res?.data?.message || res?.message || 'Something went wrong.');
          }
          setProcessing(false);
        })
        .catch((error) => {
          showErrorToastr(
            error?.response?.data?.message || error?.message || 'Something went wrong.'
          );
          setProcessing(false);
        });
    }
  };

  const items = watch('items');
  const onDependentFieldChange = (index, key, newValue) => {
    if (key === 'boxType') {
      setValue(`items.${index}.weight`, items[index].quantity * Number(newValue));
      setValue(
        `items.${index}.total`,
        items[index].rate * items[index].quantity * Number(newValue)
      );
    } else if (key === 'quantity') {
      setValue(`items.${index}.weight`, items[index].boxType * Number(newValue));
      setValue(`items.${index}.total`, items[index].rate * items[index].boxType * Number(newValue));
    } else if (key === 'weight') {
      setValue(`items.${index}.total`, items[index].rate * Number(newValue));
    } else if (key === 'rate') {
      setValue(`items.${index}.total`, Number(newValue) * items[index].weight);
    }
  };

  const onProductChange = (idx, product) => {
    if (product) {
      setValue(`items.${idx}.rate`, Number(product.rate));
      setValue(`items.${idx}.total`, Number(product.rate) * items[idx].weight);
    }
  };

  useEffect(() => {
    let currentTotal = 0;
    let currentWeight = 0;

    if (fields.length > 0 && items) {
      items.forEach((orderItem) => {
        if (orderItem.quantity > 0) {
          currentTotal += orderItem.total;
          currentWeight += orderItem.weight;
        }
      });

      setNetAmount(currentTotal);
      setNetWeight(currentWeight);
    }
  }, [items]);

  useEffect(async () => {
    try {
      const pData = await getProducts();
      if (pData.success) {
        setProductOptions(pData.data.products);
      }
      const bData = getBoxesList();
      setBoxesOptions(bData.data);
    } catch (error) {
      // TODO: show error toastr
    }
  }, []);

  useEffect(() => {
    if (editId) {
      setDataLoading(true);
      getOrderById(editId)
        .then((res) => {
          if (res.success) {
            setValue(
              'items',
              res.data.orderItems.map((i) => ({ ...i, name: { label: i.name, rate: i.rate } }))
            );
            setValue('party', { partyId: res.data.partyId, name: res.data.party.name });
            setNetWeight(res.data.netWeight);
            setNetAmount(res.data.netAmount);
            setOrderDate(res.data.createdAt);
          }
          setDataLoading(false);
        })
        .catch((error) => {
          showErrorToastr(
            error?.response?.data?.message || error?.message || 'Something went wrong.'
          );
          setProcessing(false);
        });
    } else {
      setDataLoading(false);
    }
  }, [editId]);

  const defaultValues = getValues();
  return (
    <PrivateWrapper pageName={pageName}>
      <Box p={2}>
        <Card variant="outlined" sx={{ background: 'transparent' }}>
          {dataLoading && (
            <Box p={5} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={25} />
            </Box>
          )}
          {!dataLoading && (
            <CardContent>
              <Typography variant="h6" className={classes.itemsHeading}>
                Order Details
              </Typography>
              <form name="order-form" id="order-form" onSubmit={handleSubmit(onSubmit)}>
                <Grid sx={{ mb: 2 }} container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Controller
                      control={control}
                      id="party"
                      name="party"
                      defaultValue={defaultValues.party}
                      rules={{ ...Validations.REQUIRED }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div>
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            value={value}
                            defaultValue={defaultValues.party}
                            getOptionLabel={(optn) => optn.name}
                            getOptionValue={(optn) => optn.partyId}
                            loadOptions={loadParties}
                            onChange={onChange}
                            placeholder="Party"
                            styles={{
                              container: (st) => ({
                                ...st,
                                width: '100%',
                                maxWidth: '300px',
                                marginTop: 8,
                              }),
                            }}
                          />
                          {error && <Typography color="error">{error.message}</Typography>}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DesktopDatePicker
                      label="Date"
                      inputFormat="dd/MM/yyyy"
                      value={orderDate}
                      onChange={setOrderDate}
                      renderInput={(params) => (
                        <TextField
                          required
                          sx={{ mt: 1, width: '100%', maxWidth: 300 }}
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center  ',
                  }}
                >
                  <Typography variant="h6" className={classes.itemsHeading}>
                    Order Items
                  </Typography>
                  {fields.length === 0 && (
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={handleAddItem}
                      className={classes.addItemButton}
                    >
                      Add Item
                    </Button>
                  )}
                </div>
                {fields.map((item, index) => (
                  <>
                    <Grid sx={{ mb: 2, alignItems: 'center' }} container spacing={2}>
                      <Grid item xs={12} lg={3} md={6} sm={6}>
                        <Controller
                          control={control}
                          id={`items.${index}.name`}
                          name={`items.${index}.name`}
                          rules={{ ...Validations.REQUIRED }}
                          defaultValue={defaultValues.items[index].name}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              margin="dense"
                              id={`items.${index}.name`}
                              clearText={false}
                              fullWidth
                              onChange={(e, nv) => {
                                if (nv !== null || nv !== undefined) {
                                  onProductChange(index, nv);
                                  onChange(nv);
                                }
                              }}
                              value={value}
                              options={productOptions.map((p) => ({
                                label: p.name,
                                rate: p.rate,
                              }))}
                              renderInput={(params) => (
                                <TextField
                                  margin="dense"
                                  label="Product"
                                  fullWidth
                                  sx={{ mt: error ? 2.3 : -0.5 }}
                                  variant="standard"
                                  error={!!error}
                                  helperText={error ? error?.message : undefined}
                                  {...params}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2} md={6} sm={6}>
                        <Controller
                          control={control}
                          id="boxType"
                          name={`items.${index}.boxType`}
                          rules={{ ...Validations.REQUIRED_SHORT_MSG }}
                          defaultValue={defaultValues.items[index].boxType}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="dense"
                              select
                              label="Box"
                              className={classes.formInput}
                              variant="standard"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                onDependentFieldChange(index, 'boxType', e.target.value);
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                            >
                              {boxesOptions.map((k) => (
                                <MenuItem value={k}>{`${k} KG`}</MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={1} md={3} sm={3}>
                        <Controller
                          control={control}
                          id="quantity"
                          name={`items.${index}.quantity`}
                          rules={{ ...Validations.REQUIRED_SHORT_MSG }}
                          defaultValue={defaultValues.items[index].quantity}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="dense"
                              type="number"
                              label="Quantity"
                              fullWidth
                              InputProps={{ inputProps: { min: 1 } }}
                              variant="standard"
                              sx={{ mt: error ? 2.5 : -0.5 }}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                onDependentFieldChange(index, 'quantity', e.target.value);
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2} md={3} sm={3}>
                        <Controller
                          control={control}
                          id="weight"
                          name={`items.${index}.weight`}
                          rules={{ ...Validations.REQUIRED_SHORT_MSG }}
                          defaultValue={defaultValues.items[index].weight}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="dense"
                              label="Weight"
                              fullWidth
                              sx={{ mt: error ? 2.5 : -0.5 }}
                              variant="standard"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                onDependentFieldChange(index, 'weight', e.target.value);
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={1} md={3} sm={3}>
                        <Controller
                          control={control}
                          id="rate"
                          name={`items.${index}.rate`}
                          rules={{ ...Validations.REQUIRED_SHORT_MSG }}
                          defaultValue={defaultValues.items[index].rate}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="dense"
                              label="Rate"
                              fullWidth
                              variant="standard"
                              sx={{ mt: error ? 2.5 : -0.5 }}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                onDependentFieldChange(index, 'rate', e.target.value);
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2} md={2} sm={2}>
                        <Controller
                          control={control}
                          id="total"
                          name={`items.${index}.total`}
                          // rules={{ ...Validations.REQUIRED }}
                          defaultValue={item.boxType * item.quantity * item.rate}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              margin="dense"
                              label="Total"
                              fullWidth
                              variant="standard"
                              className={classes.disabledInput}
                              sx={{ mt: -0.5 }}
                              disabled
                              value={INR.format(value)}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={1} md={1} sm={1}>
                        <IconButton
                          color="primary"
                          size="small"
                          disabled={fields.length === 1}
                          variant="contained"
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon
                            fontSize="small"
                            color={fields.length === 1 ? 'gray' : 'error'}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mb: 1 }} />
                    <Grid
                      sx={{
                        mb: 2,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                      container
                      spacing={2}
                    >
                      {index === fields.length - 1 && (
                        <Grid item>
                          <Button
                            color="secondary"
                            size="small"
                            variant="contained"
                            onClick={handleAddItem}
                            className={classes.addItemButton}
                          >
                            Add Item
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ))}
                <Grid
                  sx={{
                    mb: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  container
                  spacing={2}
                >
                  <Grid item xs={12} sm={10}>
                    <Typography variant="h6" style={{ border: 'none', fontWeight: 'bold' }}>
                      Total Weight &nbsp; &nbsp; &nbsp;{`${Number(netWeight || 0).toFixed(2)} KG`}
                    </Typography>
                    <Typography variant="h6" style={{ border: 'none', fontWeight: 'bold' }}>
                      Total Amount &nbsp;{INR.format(netAmount)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      form="order-form"
                      endIcon={processing && <CircularProgress size={25} />}
                      disabled={processing || fields.length === 0}
                      color="primary"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          )}
        </Card>
      </Box>
    </PrivateWrapper>
  );
};

OrderForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

OrderForm.defaultProps = {
  match: {
    params: {
      id: 0,
    },
  },
};

export default OrderForm;
