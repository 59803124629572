import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

export const getOrders = async (skip) => {
  const result = await getApiCall(`/orders?skip=${skip || 0}`);
  return result.data;
};

export const getOrderById = async (orderId) => {
  const result = await getApiCall(`/orders/${orderId}`);
  return result.data;
};

export const createOrder = async (orderData) => {
  const result = await postApiCall(`/orders`, orderData);
  return result.data;
};

export const updateOrderById = async (orderId, orderData) => {
  const result = await postApiCall(`/orders/${orderId}`, orderData);
  return result.data;
};

export const deleteOrderById = async (orderId) => {
  const result = await deleteApiCall(`/orders/${orderId}`);
  return result.data;
};
