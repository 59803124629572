import { deleteApiCall, getApiCall } from '../utils/Api';

export const getSyncLogs = async (skip) => {
  const result = await getApiCall(`/sync-logs?skip=${skip || 0}`);
  return result.data;
};

export const deleteSyncLogById = async (logId) => {
  const result = await deleteApiCall(`/sync-logs/${logId}`);
  return result.data;
};
