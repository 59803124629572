import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import Validations from '../../utils/Validations';
import { createParty, getPartyById, updatePartyById } from '../../services/Party';
import { DataStatus } from '../../configs/Constants';
import useToastr from '../../hooks/useToastr';

const PartyForm = ({ title, onClose, onSave, editId }) => {
  const { control, handleSubmit, setValue } = useForm();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [processing, setProcessing] = useState(false);

  const onSubmit = async (data) => {
    try {
      setProcessing(true);
      if (editId) {
        const result = await updatePartyById(editId, {
          name: data.name,
          city: data.city,
          contact: data.contact,
          status: data.status,
        });
        if (result.success) {
          showSuccessToastr('Party updated successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setProcessing(false);
        onSave();
      } else {
        const result = await createParty({
          name: data.name,
          city: data.city,
          contact: data.contact,
          // TODO: Ask Later
          closingBalance: 0,
          status: data.status,
        });
        if (result.success) {
          showSuccessToastr('Party created successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setProcessing(false);
        onSave();
      }
      setProcessing(false);
    } catch (error) {
      showErrorToastr(error?.response?.data?.message || error?.message || 'Something went wrong.');
      setProcessing(false);
      onClose();
    }
  };

  useEffect(() => {
    if (editId) {
      getPartyById({ partyId: editId, skipOrder: -1 })
        .then((result) => {
          if (result.success) {
            const { data } = result;
            setValue('name', data.name);
            setValue('city', data.city);
            setValue('contact', data.contact);
            setValue('status', data.status);
          }
        })
        .catch((error) => {
          showErrorToastr(
            error?.response?.data?.message || error?.message || 'Something went wrong.'
          );
          onClose();
        });
    }
  }, [editId]);

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id="manage-party" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column">
            <Controller
              control={control}
              id="name"
              name="name"
              rules={{ ...Validations.REQUIRED }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="Name"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
            <Controller
              control={control}
              id="city"
              name="city"
              rules={{ ...Validations.REQUIRED }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="City"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
            <Controller
              control={control}
              id="contact"
              name="contact"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="Contact"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />

            <Controller
              control={control}
              id="status"
              name="status"
              rules={{ ...Validations.REQUIRED }}
              defaultValue={1}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  label="Status"
                  select
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error?.message : null}
                >
                  <MenuItem value={DataStatus.ACTIVE}>Active</MenuItem>
                  <MenuItem value={DataStatus.INACTIVE}>InActive</MenuItem>
                </TextField>
              )}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="manage-party"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartyForm;

PartyForm.propTypes = {
  editId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
