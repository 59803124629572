import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns/esm';
import enInLocale from 'date-fns/locale/en-IN';
import { useHistory } from 'react-router-dom';
import PrivateWrapper from '../../layouts/Private';
import { getOrderById, deleteOrderById } from '../../services/Order';
import useToastr from '../../hooks/useToastr';
import { INR } from '../../configs/Constants';
import useStyles from '../../theme/styles/views/Orders';
import RoutePaths from '../../configs/Routes';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import PreviewInvoice from '../../components/orders/PreviewInvoice';

const OrderDetails = ({ match }) => {
  const orderId = Number(match.params.id) || 0;
  const pageName = orderId ? `ODR - ${orderId}` : 'Order Summary';

  const classes = useStyles();
  const history = useHistory();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [orderDetails, setOrderDetails] = useState(null);

  const [dataLoading, setDataLoading] = useState(true);
  const [dataLoadError, setDataLoadError] = useState('');
  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] = useState(false);
  const [openPreviewInvoiceDialog, setOpenPreviewInvoiceDialog] = useState(false);

  const childColumns = [
    { headerText: 'Item', field: 'pType', textAlign: 'left' },
    { headerText: 'Box', field: 'bType', textAlign: 'left' },
    { headerText: 'Quantity', field: 'quantity', textAlign: 'right' },
    { headerText: 'Weight', field: 'weight', textAlign: 'right' },
    { headerText: 'Rate', field: 'rate', textAlign: 'right' },
    { headerText: 'Amount', field: 'total', textAlign: 'right' },
  ];

  useEffect(() => {
    setDataLoading(true);
    setDataLoadError('');
    getOrderById(orderId)
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setOrderDetails(data);
          setDataLoading(false);
        }
      })
      .catch((error) => {
        setDataLoadError(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
      });
  }, []);

  if (dataLoading) {
    return (
      <PrivateWrapper pageName={pageName}>
        <Box p={5} display="flex" height="90vh" alignItems="center" justifyContent="center">
          {dataLoadError || <CircularProgress size={25} />}
        </Box>
      </PrivateWrapper>
    );
  }

  const editOrder = () => history.push(RoutePaths.EDIT_ORDER.replace(':id', orderId));

  const confirmDelete = () => setOpenDeleteOrderDialog(true);

  const deleteOrder = () => {
    deleteOrderById(orderId)
      .then((result) => {
        if (result.success) {
          showSuccessToastr('Order deleted successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setOpenDeleteOrderDialog(false);
        history.replace(RoutePaths.ORDERS);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setOpenDeleteOrderDialog(false);
      });
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
            <div className={classes.filterLeft} style={{ marginTop: 0 }}>
              <div className={classes.moveRight}>
                <IconButton size="small" onClick={editOrder}>
                  <EditIcon fontSize="medium" color="primary" />
                </IconButton>
                <IconButton size="small" onClick={() => setOpenPreviewInvoiceDialog(true)}>
                  <PrintIcon fontSize="medium" color="secondary" />
                </IconButton>
                <IconButton size="small" onClick={confirmDelete}>
                  <DeleteIcon fontSize="medium" color="error" />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card variant="outlined" sx={{ background: 'transparent' }}>
              <CardHeader title="Order Details" />
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <List sx={{ p: 0 }}>
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            variant: 'body2',
                          }}
                          secondaryTypographyProps={{
                            fontWeight: 'bolder',
                            variant: 'body2',
                          }}
                          primary="Order Number"
                          secondary={orderDetails.id}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            variant: 'body2',
                          }}
                          secondaryTypographyProps={{
                            fontWeight: 'bolder',
                            variant: 'body2',
                          }}
                          primary="Order Date"
                          secondary={format(new Date(orderDetails.createdAt), 'dd MMM yyyy', {
                            locale: enInLocale,
                          })}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Total Amount"
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            variant: 'body2',
                          }}
                          secondaryTypographyProps={{
                            fontWeight: 'bolder',
                            variant: 'body2',
                          }}
                          secondary={INR.format(orderDetails.netAmount)}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List sx={{ p: 0 }}>
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            variant: 'body2',
                          }}
                          secondaryTypographyProps={{
                            fontWeight: 'bolder',
                            variant: 'body2',
                          }}
                          primary="Party Name"
                          secondary={orderDetails.party.name}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            variant: 'body2',
                          }}
                          secondaryTypographyProps={{
                            fontWeight: 'bolder',
                            variant: 'body2',
                          }}
                          primary="Party City"
                          secondary={orderDetails.party.city}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Total Weight"
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                            variant: 'body2',
                          }}
                          secondaryTypographyProps={{
                            fontWeight: 'bolder',
                            variant: 'body2',
                          }}
                          secondary={`${orderDetails.netWeight} KG`}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Card variant="outlined" sx={{ background: 'transparent' }}>
              <CardHeader title="Actions" />
              <Divider />
              <CardContent sx={{ p: 0, py: 1 }}>
                <List sx={{ p: 0 }}>
                  <ListItem button onClick={editOrder}>
                    <ListItemIcon>
                      <EditIcon fontSize="medium" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Edit" color="primary" />
                  </ListItem>
                  <ListItem button onClick={() => setOpenPreviewInvoiceDialog(true)}>
                    <ListItemIcon>
                      <VisibilityIcon fontSize="medium" color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Preview Invoice" color="secondary" />
                  </ListItem>
                  <ListItem button onClick={confirmDelete}>
                    <ListItemIcon>
                      <DeleteIcon fontSize="medium" color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" color="error" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card variant="outlined" sx={{ background: 'transparent' }}>
              <CardHeader title="Order Items" />
              <Divider />
              <CardContent style={{ padding: 0 }}>
                <TableContainer>
                  <Table
                    aria-label="simple table"
                    sx={{
                      '& .MuiTableHead-root': {
                        backgroundColor: (theme) => theme.palette.action.disabledBackground,
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {childColumns.map((col) => (
                          <TableCell align={col.textAlign || 'left'} key={`col-${col.field}`}>
                            <span>{col.headerText}</span>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails.orderItems.length === 0 && (
                        <TableCell
                          align="center"
                          size="medium"
                          colSpan={10}
                          className={classes.noRecordFoundText}
                        >
                          No Orders found
                        </TableCell>
                      )}
                      {orderDetails.orderItems &&
                        orderDetails.orderItems.map((item) => (
                          <TableRow key={`in-${item.id}`}>
                            <TableCell>{`${item.name}`}</TableCell>
                            <TableCell>{item.boxType}</TableCell>
                            <TableCell align="right">{item.quantity}</TableCell>
                            <TableCell align="right">{`${item.weight} KG`}</TableCell>
                            <TableCell align="right">{INR.format(item.rate)}</TableCell>
                            <TableCell align="right">{INR.format(item.total)}</TableCell>
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell colSpan={4} />
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          Total Weight
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {`${orderDetails.netWeight} KG`}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4} style={{ border: 'none' }} />
                        <TableCell align="right" style={{ border: 'none', fontWeight: 'bold' }}>
                          Total Amount
                        </TableCell>
                        <TableCell align="right" style={{ border: 'none', fontWeight: 'bold' }}>
                          {INR.format(orderDetails.netAmount)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} />
        </Grid>
      </Box>
      {openDeleteOrderDialog && (
        <ConfirmDialog
          title="Are you sure want to delete this order?"
          isOpen={openDeleteOrderDialog}
          onConfirm={deleteOrder}
          onClose={() => {
            setOpenDeleteOrderDialog(false);
          }}
        />
      )}
      {openPreviewInvoiceDialog && (
        <PreviewInvoice
          orderId={orderId}
          onClose={() => {
            setOpenPreviewInvoiceDialog(false);
          }}
        />
      )}
    </PrivateWrapper>
  );
};

OrderDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

OrderDetails.defaultProps = {
  match: {
    params: {
      id: 0,
    },
  },
};

export default OrderDetails;
