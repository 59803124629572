import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import enInLocale from 'date-fns/locale/en-IN';
import clsx from 'clsx';
import PrivateWrapper from '../layouts/Private';
import { DT_PAGE_SIZE, SYNC_MODE, SYNC_MODE_VALUES, SYNC_STATUS } from '../configs/Constants';
import { deleteSyncLogById, getSyncLogs } from '../services/SyncLog';
import ConfirmDialog from '../components/common/ConfirmDialog';
import useToastr from '../hooks/useToastr';

const AppSheetSyncLogs = () => {
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  const [selectedSyncLog, setSelectedSyncLog] = useState(null);
  const [openDeleteSyncLogDialog, setOpenDeleteSyncLogDialog] = useState(false);

  const onDelete = (id) => {
    setSelectedSyncLog(id);
    setOpenDeleteSyncLogDialog(true);
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      flex: 1,
      minWidth: 60,
    },
    {
      headerName: 'Data Id',
      field: 'dataId',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: 'Data Type',
      field: 'dataType',
      flex: 1,
      minWidth: 80,
      align: 'center',
      valueGetter: ({ value }) =>
        // eslint-disable-next-line no-nested-ternary
        value ? (Number(value) === 1 ? 'ORDER' : 'COLLECTION') : 'UNKNOWN',
      cellClassName: (prms) =>
        clsx('dg-data-type', {
          order: Number(prms?.row?.dataType || 0) === 1,
          collection: Number(prms?.row?.dataType || 0) === 2,
        }),
    },
    {
      headerName: 'Synced At',
      field: 'createdAt',
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value }) => value && format(new Date(value), 'P', { locale: enInLocale }),
    },
    {
      headerName: 'Updated At',
      field: 'modifiedAt',
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value }) => value && format(new Date(value), 'P', { locale: enInLocale }),
    },
    {
      headerName: 'Sync Type',
      field: 'syncType',
      flex: 1,
      minWidth: 50,
      align: 'center',
      valueGetter: ({ value }) => SYNC_MODE[Number(value || 0)],
      cellClassName: (prms) =>
        clsx('dg-sync-type', {
          delete: Number(prms?.row?.syncType || 0) === SYNC_MODE_VALUES.Delete,
          add: Number(prms?.row?.syncType || 0) === SYNC_MODE_VALUES.Add,
          update: Number(prms?.row?.syncType || 0) === SYNC_MODE_VALUES.Update,
          spreadSheet: Number(prms?.row?.syncType || 0) === SYNC_MODE_VALUES.Spreadsheet,
        }),
    },
    {
      headerName: 'Sync Status',
      field: 'syncStatus',
      type: 'number',
      flex: 1,
      minWidth: 50,
      align: 'center',
      valueGetter: ({ value }) => SYNC_STATUS[Number(value || 0)],
      cellClassName: (prms) =>
        clsx('dg-sync-status', {
          failed: Number(prms?.row?.syncStatus || 0) === SYNC_STATUS.FAILED,
          success: Number(prms?.row?.syncStatus || 0) === SYNC_STATUS.SUCCESS,
        }),
    },

    {
      headerName: 'Actions',
      field: 'actions',
      textAlign: 'right',
      type: 'actions',
      flex: 1,
      minWidth: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon fontSize="small" color="error" />}
          label="Delete"
          onClick={() => {
            onDelete(params.id);
          }}
        />,
      ],
    },
  ];

  const deleteSyncLog = () => {
    deleteSyncLogById(selectedSyncLog)
      .then((result) => {
        if (result.success) {
          showSuccessToastr('Sync Log deleted successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setOpenDeleteSyncLogDialog(false);
        setSelectedSyncLog(null);
        setRefreshGrid(!refreshGrid);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setOpenDeleteSyncLogDialog(false);
        setSelectedSyncLog(null);
      });
  };

  useEffect(() => {
    setDataLoading(true);
    const skip = currentPage === 0 ? 0 : currentPage * DT_PAGE_SIZE;
    getSyncLogs(skip)
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setRows(data.gsSyncLogs);
          setTotalRecords(data.totalRecords);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setRows([]);
        setDataLoading(false);
      });
  }, [currentPage, refreshGrid]);

  return (
    <PrivateWrapper pageName="Sync Logs">
      <div style={{ height: '80vh', width: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            height: '95%',
            pt: 8,
            pb: 4,
            '& .dg-sync-status.failed': {
              background: '#faafaf',
              color: '#fc0303',
              fontWeight: 'bold',
            },
            '& .dg-sync-status.success': {
              background: '#cafcd4',
              color: '#008a1c',
              fontWeight: 'bold',
            },
            '& .dg-data-type.order': {
              background: '#ffd2bd',
              color: '#ff4d00',
              fontWeight: 'bold',
            },
            '& .dg-data-type.collection': {
              background: '#8ae1f2',
              color: '#08839c',
              fontWeight: 'bold',
            },
            '& .dg-sync-type.delete': {
              background: '#faafaf',
              color: '#fc0303',
              fontWeight: 'bold',
            },
            '& .dg-sync-type.add': {
              //  background: '#cafcd4',
              color: '#008a1c',
              fontWeight: 'bold',
            },
            '& .dg-sync-type.update': {
              //   background: '#ffd69c',
              color: '#c27100',
              fontWeight: 'bold',
            },
            '& .dg-sync-type.spreadSheet': {
              //  background: '#abb3ff',
              color: '#000f99',
              fontWeight: 'bold',
            },
          }}
        >
          <DataGrid
            dataLoading={dataLoading}
            rows={rows}
            rowCount={totalRecords}
            columns={columns}
            pageSize={DT_PAGE_SIZE}
            page={currentPage}
            paginationMode="server"
            onPageChange={setCurrentPage}
          />
        </Box>
      </div>
      {openDeleteSyncLogDialog && (
        <ConfirmDialog
          title="Are you sure want to delete this sync log?"
          isOpen={openDeleteSyncLogDialog}
          onConfirm={deleteSyncLog}
          onClose={() => {
            setOpenDeleteSyncLogDialog(false);
            setSelectedSyncLog(null);
          }}
        />
      )}
    </PrivateWrapper>
  );
};

export default AppSheetSyncLogs;
