import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import html2pdf from 'html2pdf.js';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import TableContainer from '@mui/material/TableContainer';
import useStyles from '../../theme/styles/views/Orders';
import { INR } from '../../configs/Constants';
import { getOrderById } from '../../services/Order';
import useToastr from '../../hooks/useToastr';

function TabPanel(props) {
  const { children, value, index, ...otherProps } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const PreviewInvoice = ({ onClose, orderId }) => {
  const classes = useStyles();
  const { showErrorToastr } = useToastr();

  const [orderDetails, setOrderDetails] = useState();

  const [dataLoading, setDataLoading] = useState(true);
  const [printProcessing, setPrintProcessing] = useState(false);

  const [value, setValue] = useState(0);

  const printReceipt = () => {
    setPrintProcessing(true);
    const elm = document.getElementById('active-order-details-display-container');
    html2pdf()
      .then(() => {
        setPrintProcessing(false);
      })
      .catch((error) => {
        showErrorToastr(error?.message || 'Something went wrong.');
        setPrintProcessing(false);
      })
      .set({
        margin: [5, 10, 5, 10], // top, left, bottom, right
        image: { type: 'jpeg', quality: 0.98 },
        filename: `${format(new Date(orderDetails.createdAt), 'dd/MM/yyyy')}-${orderDetails.id}`,
      })
      .from(elm)
      .save();
  };

  useEffect(() => {
    setDataLoading(true);
    getOrderById(orderId)
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setOrderDetails(data);
          setDataLoading(false);
        }
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        onClose();
      });
  }, []);

  return (
    <Dialog
      open
      fullScreen
      onClose={onClose}
      style={{ margin: 0 }}
      sx={{
        marginTop: -1,
        height: '100vh',
        '& .MuiPaper-root': {
          margin: 0,
          borderRadius: 0,
          marginTop: -0.3,
        },
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: (theme) => theme.palette.action.disabledBackground,
        }}
      >
        <Toolbar>
          <div className={classes.filterLeft} style={{ margin: 0, alignItems: 'center' }}>
            <IconButton edge="start" color="secondary" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="secondary">
              Print Invoice
            </Typography>
            <div className={classes.moveRight}>
              <TextField select value={value} onChange={(e) => setValue(e.target.value)}>
                <MenuItem value={0}>Format 1</MenuItem>
                <MenuItem value={1}>Format 2</MenuItem>
              </TextField>

              <IconButton size="small" sx={{ ml: 1 }} onClick={printReceipt}>
                {printProcessing ? (
                  <CircularProgress size={18} color="primary" />
                ) : (
                  <SaveIcon fontSize="medium" color="primary" />
                )}
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <DialogContent
        id="active-order-details-display-container"
        style={{ border: '2px soild #000', borderRadius: 2 }}
      >
        <TabPanel value={value} index={0}>
          {dataLoading ? (
            <Box p={5} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={25} />
            </Box>
          ) : (
            <PreviewOne orderDetails={orderDetails} />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {dataLoading ? (
            <Box p={5} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={25} />
            </Box>
          ) : (
            <PreviewTwo orderDetails={orderDetails} />
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

const PreviewOne = ({ orderDetails }) => {
  const classes = useStyles();

  const childColumns = [
    { headerText: 'Item', field: 'pType', textAlign: 'left' },
    { headerText: 'Quantity', field: 'quantity', textAlign: 'right' },
    { headerText: 'Weight', field: 'weight', textAlign: 'right' },
    { headerText: 'Rate', field: 'rate', textAlign: 'right' },
    { headerText: 'Amount', field: 'total', textAlign: 'right' },
  ];

  return (
    <Box mt={2}>
      <Table className={classes.tableData} aria-label="simple table">
        <TableRow>
          <TableCell width="20%" style={{ border: 'none', fontWeight: 'bold' }}>
            Order Number
          </TableCell>
          <TableCell style={{ border: 'none', color: '#000' }}>{orderDetails.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell width="20%" style={{ border: 'none', fontWeight: 'bold' }}>
            Order Date
          </TableCell>
          <TableCell style={{ border: 'none', color: '#000' }}>
            {format(new Date(orderDetails.createdAt), 'dd/MM/yyyy')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ border: 'none', fontWeight: 'bold' }}>Party</TableCell>
          <TableCell style={{ border: 'none', color: '#000' }}>{orderDetails.party.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ border: 'none', fontWeight: 'bold' }}>City</TableCell>
          <TableCell style={{ border: 'none', color: '#000' }}>{orderDetails.party.city}</TableCell>
        </TableRow>
      </Table>

      <Table
        className={classes.tableData}
        aria-label="simple table"
        sx={{
          mt: 2,
          '& .MuiTableHead-root': {
            backgroundColor: (theme) => theme.palette.action.disabledBackground,
          },
        }}
      >
        <TableHead>
          <TableRow>
            {childColumns.map((col) => (
              <TableCell align={col.textAlign || 'left'} key={`col-${col.field}`}>
                <span>{col.headerText}</span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderDetails.orderItems.length === 0 && (
            <TableCell
              align="center"
              size="medium"
              colSpan={10}
              className={classes.noRecordFoundText}
            >
              No Orders found
            </TableCell>
          )}
          {orderDetails.orderItems.map((item) => (
            <TableRow key={`in-${item.id}`}>
              <TableCell>FRESH BUTTON MUSHROOMS</TableCell>
              <TableCell align="right">{item.quantity}</TableCell>
              <TableCell align="right">{`${item.weight} KG`}</TableCell>
              <TableCell align="right">{INR.format(item.rate)}</TableCell>
              <TableCell align="right">{INR.format(item.total)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3} style={{ border: 'none' }} />
            <TableCell align="right" style={{ border: 'none', fontWeight: 'bold' }}>
              Total Weight
            </TableCell>
            <TableCell align="right" style={{ border: 'none', fontWeight: 'bold' }}>
              {`${orderDetails.netWeight} KG`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3} style={{ border: 'none' }} />
            <TableCell align="right" style={{ border: 'none', fontWeight: 'bold' }}>
              Total Amount
            </TableCell>
            <TableCell align="right" style={{ border: 'none', fontWeight: 'bold' }}>
              {INR.format(orderDetails.netAmount)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

PreviewOne.propTypes = {
  orderDetails: PropTypes.objectOf(PropTypes.any).isRequired,
};

const PreviewTwo = ({ orderDetails }) => {
  const classes = useStyles();

  const childColumns = [
    { headerText: 'Item', field: 'pType', textAlign: 'left' },
    { headerText: 'Quantity', field: 'quantity', textAlign: 'right' },
    { headerText: 'Weight', field: 'weight', textAlign: 'right' },
    { headerText: 'Rate', field: 'rate', textAlign: 'right' },
    { headerText: 'Amount', field: 'total', textAlign: 'right' },
  ];

  return (
    <>
      <Typography variant="h4" sx={{ color: (theme) => theme.palette.text.invoiceOrange }}>
        Letsanjoy Mushrooms Pvt Ltd
      </Typography>
      <Typography variant="p" sx={{ pt: 1, fontWeight: 'bolder', color: '#000' }}>
        Dharpuri, Vadavali-Chaveli Cross Road, Ta:Chanasma, Dist:Patan Gujarat-384355
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mt: 0.25,
          p: 1,
          borderTop: (theme) => `6px solid ${theme.palette.text.invoiceOrange}`,
          backgroundColor: (theme) => theme.palette.action.disabledBackground,
        }}
      >
        <Typography variant="p" sx={{ pt: 1, fontWeight: 'bolder', color: '#000' }}>
          Invoice Number: {orderDetails.id}
        </Typography>
        <Typography variant="p" sx={{ pt: 1, fontWeight: 'bolder', color: '#000' }}>
          Invoice Date: {format(new Date(orderDetails.createdAt), 'dd/MM/yyyy')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          mt: 0.25,
          py: 2,
        }}
      >
        <Typography variant="h6" sx={{ pt: 1 }}>
          BILL TO
        </Typography>
        <Typography variant="p" sx={{ pt: 1, fontWeight: 'bolder' }}>
          {orderDetails.party.name}
        </Typography>
        <Typography variant="p">{orderDetails.party.city}</Typography>
      </Box>
      <TableContainer>
        <Table
          aria-label="simple table"
          sx={{
            mt: 2,
            '& .MuiTableHead-root': {
              backgroundColor: (theme) => theme.palette.action.disabledBackground,
            },
            '& .MuiTableCell-root': {
              textTransform: 'uppercase',
              padding: 1.5,
              color: '#000',
            },
          }}
        >
          <TableHead>
            <TableRow>
              {childColumns.map((col) => (
                <TableCell align={col.textAlign || 'left'} key={`col-${col.field}`}>
                  <span>{col.headerText}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails.orderItems.length === 0 && (
              <TableCell
                align="center"
                size="medium"
                colSpan={10}
                className={classes.noRecordFoundText}
              >
                No Orders found
              </TableCell>
            )}
            {orderDetails.orderItems.map((item) => (
              <TableRow key={`in-${item.id}`}>
                <TableCell>FRESH BUTTON MUSHROOMS</TableCell>

                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{`${item.weight} KG`}</TableCell>
                <TableCell align="right">{INR.format(item.rate)}</TableCell>
                <TableCell align="right">{INR.format(item.total)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} style={{ border: 'none' }} />
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                Total Weight
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {`${orderDetails.netWeight} KG`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3} style={{ border: 'none' }} />
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                Total Amount
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {INR.format(orderDetails.netAmount)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ pt: 1, color: '#000' }}>
        Terms and Conditions
        <ul>
          <li>Goods once sold will not be taken back or exchanged.</li>
          <li>All disputes are subject to Chanasma jurisdiction only.</li>
        </ul>
      </Box>
    </>
  );
};

PreviewTwo.propTypes = {
  orderDetails: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PreviewInvoice;

PreviewInvoice.propTypes = {
  orderId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
