import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

export const getProducts = async (skip) => {
  const result = await getApiCall(`/products?skip=${skip || 0}`);
  return result.data;
};

export const getProductById = async (productId) => {
  const result = await getApiCall(`/products/${productId}`);
  return result.data;
};

export const createProduct = async (productData) => {
  const result = await postApiCall(`/products`, productData);
  return result.data;
};

export const updateProductById = async (productId, productData) => {
  const result = await postApiCall(`/products/${productId}`, productData);
  return result.data;
};

export const deleteProductById = async (productId) => {
  const result = await deleteApiCall(`/products/${productId}`);
  return result.data;
};
