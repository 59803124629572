import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { format } from 'date-fns';
import enInLocale from 'date-fns/locale/en-IN';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import useStyles from '../../theme/styles/views/Orders';
import PrivateWrapper from '../../layouts/Private';
import { DT_PAGE_SIZE, INR } from '../../configs/Constants';
import { deleteOrderById, getOrders } from '../../services/Order';
import useToastr from '../../hooks/useToastr';
import RoutePaths from '../../configs/Routes';

const Orders = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refreshGrid, setRefreshGrid] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] = useState(false);

  const onDelete = (id) => {
    setSelectedOrder(id);
    setOpenDeleteOrderDialog(true);
  };

  const onView = (id) => {
    history.push(RoutePaths.ORDER_DETAILS.replace(':id', id));
  };

  const columns = [
    { headerName: 'ID', field: 'id', flex: 1, minWidth: 60 },
    {
      headerName: 'Party',
      field: 'name',
      valueGetter: (prms) => prms?.row?.party?.name,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => value && format(new Date(value), 'P', { locale: enInLocale }),
    },
    {
      headerName: 'Net. Weight',
      field: 'netWeight',
      valueGetter: (prms) => `${prms?.row?.netWeight || 0} KG`,
      flex: 1,
      type: 'number',
      minWidth: 80,
    },
    {
      headerName: 'Net. Amount',
      field: 'netAmount',
      valueGetter: (prms) => INR.format(prms?.row?.netAmount || 0),
      textAlign: 'right',
      flex: 1,
      type: 'number',
      minWidth: 100,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      minWidth: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<LaunchIcon fontSize="small" color="secondary" />}
          label="View"
          onClick={() => {
            onView(params.id);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon fontSize="small" color="error" />}
          label="Delete"
          onClick={() => {
            onDelete(params.id);
          }}
        />,
      ],
      flex: 1,
    },
  ];

  const deleteOrder = () => {
    deleteOrderById(selectedOrder)
      .then((result) => {
        if (result.success) {
          showSuccessToastr('Order deleted successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setOpenDeleteOrderDialog(false);
        setSelectedOrder(null);
        setRefreshGrid(!refreshGrid);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setOpenDeleteOrderDialog(false);
        setSelectedOrder(null);
      });
  };

  useEffect(() => {
    const skip = currentPage === 0 ? 0 : currentPage * DT_PAGE_SIZE;
    setLoading(true);
    getOrders(skip)
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setRows(data.orders);
          setTotalRecords(data.totalRecords);
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setRows([]);
        setLoading(false);
      });
  }, [currentPage, refreshGrid]);

  return (
    <PrivateWrapper pageName="Orders">
      <Box display="flex" flexDirection="column">
        <div className={classes.filterLeft}>
          <div className={classes.moveRight}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.addNewBtn}
              onClick={() => {
                history.push(RoutePaths.CREATE_ORDER);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
        <div style={{ height: '80vh', width: '100%' }}>
          <DataGrid
            loading={loading}
            rows={rows}
            rowCount={totalRecords}
            columns={columns}
            pageSize={DT_PAGE_SIZE}
            page={currentPage}
            paginationMode="server"
            onPageChange={setCurrentPage}
          />
        </div>

        {openDeleteOrderDialog && (
          <ConfirmDialog
            title="Are you sure want to delete this order?"
            isOpen={openDeleteOrderDialog}
            onConfirm={deleteOrder}
            onClose={() => {
              setOpenDeleteOrderDialog(false);
              setSelectedOrder(null);
            }}
          />
        )}
      </Box>
    </PrivateWrapper>
  );
};

export default Orders;
