import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import enInLocale from 'date-fns/locale/en-IN';
import useStyles from '../theme/styles/views/Orders';
import PrivateWrapper from '../layouts/Private';
import { DT_PAGE_SIZE, INR } from '../configs/Constants';
import useToastr from '../hooks/useToastr';
import { getLedger } from '../services/Ledger';
import { getParties } from '../services/Party';

const Ledger = () => {
  const classes = useStyles();
  const { showErrorToastr } = useToastr();

  const [openingBalance, setOpeningBalance] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [netBalance, setNetBalance] = useState(0);
  const [selectedParty, setSelectedParty] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [queryData, setQueryData] = useState({
    selectedParty: null,
    startDate: null,
    endDate: null,
  });

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  const columns = [
    {
      headerName: 'Description',
      field: 'description',
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value }) =>
        value && format(new Date(value), 'dd MMM yyyy', { locale: enInLocale }),
    },
    {
      headerName: 'Debit',
      field: 'debit',
      valueGetter: (prms) => INR.format(prms?.row?.debit || 0),
      flex: 1,
      type: 'number',
      minWidth: 150,
      cellClassName: () => clsx('dg-debit-column'),
    },
    {
      headerName: 'Credit',
      field: 'credit',
      valueGetter: (prms) => INR.format(prms?.row?.credit || 0),
      type: 'number',
      minWidth: 150,
      flex: 1,
      cellClassName: () => clsx('dg-credit-column'),
    },
    {
      headerName: 'Balance',
      field: 'balance',
      valueGetter: (prms) => INR.format(prms?.row?.balance || 0),
      textAlign: 'right',
      flex: 1,
      type: 'number',
      minWidth: 150,
      cellClassName: (prms) =>
        clsx('dg-balance', {
          negative: prms?.row?.balance < 0,
          positive: prms?.row?.balance > 0,
        }),
    },
  ];

  const loadParties = (searchString, callback) => {
    getParties({ skip: 0, searchString }).then((res) => {
      callback(
        res.data.parties.map((party) => ({
          name: party.name,
          partyId: party.id,
        }))
      );
    });
  };
  const getDateText = () => {
    let txt = '';
    if (queryData.startDate && queryData.endDate)
      txt = `(${format(new Date(startDate), 'dd MMM yyyy')} - ${format(
        new Date(endDate),
        'dd MMM yyyy'
      )})`;
    else if (queryData.startDate && !queryData.endDate)
      txt = `(${format(new Date(startDate), 'dd MMM yyyy')} - Now)`;
    else if (!queryData.startDate && queryData.endDate)
      txt = `(Begining - ${format(new Date(endDate), 'dd MMM yyyy')})`;
    else if (!queryData.startDate && !queryData.endDate) txt = `(Begining - Now)`;

    return txt;
  };

  const onFilterApplied = () => {
    setQueryData({ selectedParty, endDate, startDate });
    setRefreshGrid(!refreshGrid);
  };

  useEffect(() => {
    setDataLoading(true);

    let queryParams = '';
    queryParams += `?cP=${currentPage + 1}`;
    if (queryData.selectedParty) queryParams += `&pId=${queryData.selectedParty.partyId}`;
    if (queryData.startDate) queryParams += `&sD=${new Date(queryData.startDate).getTime() / 1000}`;
    if (queryData.endDate) queryParams += `&eD=${new Date(queryData.endDate).getTime() / 1000}`;

    getLedger(queryParams)
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setOpeningBalance(data?.ledgerMetaData?.openingBalance || 0);
          setNetBalance(data?.ledgerMetaData?.totalBalance || 0);
          setTotalDebit(data?.ledgerMetaData?.totalDebit || 0);
          setTotalCredit(data?.ledgerMetaData?.totalCredit || 0);
          setTotalRecords(data?.ledgerMetaData?.totalEntries || 0);
          setRows(data.ledgerEntries);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setRows([]);
        setDataLoading(false);
      });
  }, [currentPage, refreshGrid]);

  return (
    <PrivateWrapper pageName="Ledger">
      <Box display="flex" flexDirection="column">
        <div className={classes.filterLeft}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={selectedParty}
                getOptionLabel={(optn) => optn.name}
                getOptionValue={(optn) => optn.partyId}
                loadOptions={loadParties}
                onChange={setSelectedParty}
                placeholder="Party"
                styles={{ container: (st) => ({ ...st, width: '100%', marginTop: 8 }) }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="dd/MM/yyyy"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField fullWidth required sx={{ mt: 1 }} variant="outlined" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DesktopDatePicker
                label="End Date"
                inputFormat="dd/MM/yyyy"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField fullWidth required sx={{ mt: 1 }} variant="outlined" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                className={classes.addNewBtn}
                sx={{ height: 'min-content', mt: 1, mr: 1 }}
                onClick={onFilterApplied}
              >
                Apply
              </Button>
              <Button
                variant="contained"
                className={classes.addNewBtn}
                sx={{ height: 'min-content', mt: 1 }}
                onClick={() => {
                  setRefreshGrid(!refreshGrid);
                }}
              >
                Refresh
              </Button>
            </Grid>
          </Grid>
          <div className={classes.moveRight} />
        </div>
        <Grid container>
          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              component="h1"
              textAlign="center"
              sx={{ typography: { sm: 'h5' }, my: 4 }}
            >
              {queryData.selectedParty ? selectedParty.name : 'All Statement'}
              <br />
              <Typography textAlign="center" sx={{ typography: { xs: 'p', fontWeight: 600 } }}>
                {getDateText()}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} />
        </Grid>
        <Box sx={{ border: '1px solid rgba(225, 224, 226, 1)', my: 1, p: 1, borderRadius: 1 }}>
          <Grid container>
            <Grid item justifySelf="center" xs={12} sm={6} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  borderRight: {
                    md: '1px solid rgba(225, 224, 226, 1)',
                    sm: '1px solid rgba(225, 224, 226, 1)',
                  },
                  p: 1,
                }}
              >
                <Typography>Opening Balance</Typography>
                <Typography
                  sx={{
                    fontSize: 'large',
                    fontWeight: '600',
                    color: (theme) => (openingBalance > 0 ? theme.palette.success.light : 'red'),
                  }}
                >
                  {INR.format(openingBalance)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ borderRight: { md: '1px solid rgba(225, 224, 226, 1)' }, p: 1 }}
              >
                <Typography>Total Debit(-)</Typography>
                <Typography
                  sx={{
                    fontSize: 'large',
                    fontWeight: '600',
                    color: 'red',
                  }}
                >
                  {INR.format(totalDebit)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  borderRight: {
                    md: '1px solid rgba(225, 224, 226, 1)',
                    sm: '1px solid rgba(225, 224, 226, 1)',
                  },
                  p: 1,
                }}
              >
                <Typography>Total Credit(+)</Typography>
                <Typography
                  sx={{
                    fontSize: 'large',
                    fontWeight: '600',
                    color: (theme) => theme.palette.success.light,
                  }}
                >
                  {INR.format(totalCredit)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box display="flex" flexDirection="column" sx={{ p: 1 }}>
                <Typography>Net Balance</Typography>
                <Typography
                  sx={{
                    fontSize: 'large',
                    fontWeight: '600',
                    color: (theme) => (netBalance > 0 ? theme.palette.success.light : 'red'),
                  }}
                >
                  {INR.format(netBalance)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <div style={{ height: '80vh', width: '100%' }}>
          <Box
            sx={{
              height: '95%',
              width: 1,
              '& .dg-credit-column': {
                backgroundColor: (theme) => lighten(theme.palette.success.light, 0.8),
              },
              '& .dg-debit-column': {
                backgroundColor: (theme) => lighten(theme.palette.error.main, 0.8),
              },
              '& .dg-balance.negative': {
                color: 'red',
              },
              '& .dg-balance.positive': {
                color: (theme) => theme.palette.success.light,
              },
            }}
          >
            <DataGrid
              getRowId={(r) => r.id}
              dataLoading={dataLoading}
              rows={rows}
              rowCount={totalRecords}
              columns={columns}
              pageSize={DT_PAGE_SIZE}
              page={currentPage}
              paginationMode="server"
              onPageChange={setCurrentPage}
            />
          </Box>
        </div>
      </Box>
    </PrivateWrapper>
  );
};

export default Ledger;
