import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { format } from 'date-fns';
import enInLocale from 'date-fns/locale/en-IN';
import PrivateWrapper from '../layouts/Private';
import useStyles from '../theme/styles/views/Orders';
import CreateProduct from '../components/products/CreateProduct';
import { deleteProductById, getProducts } from '../services/Product';
import { INR, DT_PAGE_SIZE } from '../configs/Constants';
import ConfirmDialog from '../components/common/ConfirmDialog';
import useToastr from '../hooks/useToastr';

const Products = () => {
  const classes = useStyles();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshGrid, setRefreshGrid] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openCreateProductDialog, setOpenCreateProductDialog] = useState(false);
  const [openDeleteProductDialog, setOpenDeleteProductDialog] = useState(false);

  const onDelete = (id) => {
    setSelectedProduct(id);
    setOpenDeleteProductDialog(true);
  };

  const onEdit = (id) => {
    setSelectedProduct(id);
    setOpenCreateProductDialog(true);
  };

  const columns = [
    { headerName: 'ID', field: 'id', flex: 1, minWidth: 60 },
    { headerName: 'Name', field: 'name', flex: 1, minWidth: 200 },
    {
      headerName: 'Rate',
      field: 'rate',
      textAlign: 'right',
      valueGetter: (prms) => INR.format(prms.row.rate),
      minWidth: 80,
      type: 'number',
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => value && format(new Date(value), 'P', { locale: enInLocale }),
    },
    {
      headerName: 'Actions',
      textAlign: 'right',
      type: 'actions',
      field: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon fontSize="small" color="secondary" />}
          label="View"
          onClick={() => {
            onEdit(params.id);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon fontSize="small" color="error" />}
          label="Delete"
          onClick={() => {
            onDelete(params.id);
          }}
        />,
      ],
      allowEditing: false,
      flex: 1,
    },
  ];

  const deleteProduct = () => {
    deleteProductById(selectedProduct)
      .then((result) => {
        if (result.success) {
          showSuccessToastr('Product deleted successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setOpenDeleteProductDialog(false);
        setSelectedProduct(null);
        setRefreshGrid(!refreshGrid);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setOpenDeleteProductDialog(false);
        setSelectedProduct(null);
      });
  };

  useEffect(() => {
    const skip = currentPage === 0 ? 0 : currentPage * DT_PAGE_SIZE;
    getProducts(skip)
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setRows(data.products);
          setTotalRecords(data.totalRecords);
        }
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setRows([]);
      });
  }, [currentPage, refreshGrid]);

  return (
    <PrivateWrapper pageName="Products">
      <>
        <Box display="flex" flexDirection="column">
          <div className={classes.filterLeft}>
            <div />
            <div className={classes.moveRight}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenCreateProductDialog(true)}
                className={classes.addNewBtn}
              >
                Add New
              </Button>
            </div>
          </div>
          <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              rowCount={totalRecords}
              columns={columns}
              pageSize={DT_PAGE_SIZE}
              page={currentPage}
              paginationMode="server"
              onPageChange={setCurrentPage}
            />
          </div>
        </Box>

        {openDeleteProductDialog && (
          <ConfirmDialog
            title="Are you sure want to delete this product?"
            isOpen={openDeleteProductDialog}
            onConfirm={deleteProduct}
            onClose={() => {
              setOpenDeleteProductDialog(false);
              setSelectedProduct(null);
            }}
          />
        )}
        {openCreateProductDialog && (
          <CreateProduct
            editId={selectedProduct}
            title="Create Product"
            isOpen={openCreateProductDialog}
            onSave={() => {
              setOpenCreateProductDialog(false);
              setSelectedProduct(null);
              setRefreshGrid(!refreshGrid);
            }}
            onClose={() => {
              setOpenCreateProductDialog(false);
              setSelectedProduct(null);
            }}
          />
        )}
      </>
    </PrivateWrapper>
  );
};

export default Products;
