import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import useStyles from '../theme/styles/views/Orders';
import PrivateWrapper from '../layouts/Private';
import SettingsPassword from '../components/settings/SettingsPassword';
import SettingsProfile from '../components/settings/SettingsProfile';
import { getBoxesValue } from '../services/Settings';
import AppSettingsForm from '../components/settings/AppSettingForm';

const Settings = () => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [refreshGrid, setRefreshGrid] = useState(false);

  const [openSettingForm, setOpenSettingForm] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState({
    id: 's_boxes',
    settingName: 'Boxes',
    settingValue: '',
    settingKey: 'boxes_options',
  });

  const columns = [
    { headerName: 'Setting', field: 'settingName', flex: 1 },
    { headerName: 'Value', field: 'settingValue', flex: 1 },
    {
      headerName: 'Actions',
      type: 'actions',
      field: 'actions',
      textAlign: 'right',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon fontSize="small" color="secondary" />}
          label="Edit"
          onClick={() => {
            setOpenSettingForm(true);
            setSelectedSetting({
              settingKey: params.row?.settingKey,
              settingName: params.row?.settingName,
              settingValue: params.row?.settingValue,
            });
          }}
        />,
      ],
      sortable: false,
    },
  ];

  useEffect(() => {
    setRows([getBoxesValue()]);
  }, [refreshGrid]);

  return (
    <PrivateWrapper pageName="Settings">
      <Box display="flex" flexDirection="column">
        <div className={classes.filterLeft}>
          <Typography variant="h6">App Settings</Typography>
        </div>
        <div style={{ width: '100%' }}>
          <DataGrid autoHeight rows={rows} columns={columns} pageSize={5} />
        </div>

        <Box mt={2} mb={2}>
          <Grid container spacing={2}>
            <Grid item md={12} lg={6}>
              <SettingsPassword />
            </Grid>
            <Grid item md={12} lg={6}>
              <SettingsProfile />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {openSettingForm && (
        <AppSettingsForm
          settingValue={selectedSetting.settingValue}
          settingName={selectedSetting.settingName}
          settingKey={selectedSetting.settingKey}
          onSave={() => {
            setOpenSettingForm(false);
            setRefreshGrid(!refreshGrid);
          }}
          onClose={() => {
            setOpenSettingForm(false);
          }}
        />
      )}
    </PrivateWrapper>
  );
};

export default Settings;
