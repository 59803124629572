import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { format } from 'date-fns';
import enInLocale from 'date-fns/locale/en-IN';
import PartyForm from '../components/parties/PartyForm';
import ConfirmDialog from '../components/common/ConfirmDialog';
import useStyles from '../theme/styles/views/Orders';
import PrivateWrapper from '../layouts/Private';
import Status from '../components/common/DataStatus';
import { deletePartyById, getParties } from '../services/Party';
import { DT_PAGE_SIZE } from '../configs/Constants';
import useToastr from '../hooks/useToastr';

const Parties = () => {
  const classes = useStyles();
  const { showErrorToastr, showSuccessToastr } = useToastr();

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  const [selectedParty, setSelectedParty] = useState(null);
  const [openPartyFormDialog, setOpenPartyFormDialog] = useState(false);
  const [openDeletePartyDialog, setOpenDeletePartyDialog] = useState(false);

  const onDelete = (id) => {
    setSelectedParty(id);
    setOpenDeletePartyDialog(true);
  };

  const onEdit = (id) => {
    setSelectedParty(id);
    setOpenPartyFormDialog(true);
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      flex: 1,
      minWidth: 60,
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: 'City',
      field: 'city',
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: 'Contact',
      field: 'contact',
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: Status,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => value && format(new Date(value), 'P', { locale: enInLocale }),
    },
    {
      headerName: 'Actions',
      type: 'actions',
      field: 'actions',
      minWidth: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon fontSize="small" color="secondary" />}
          label="Edit"
          onClick={() => {
            onEdit(params.id);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon fontSize="small" color="error" />}
          label="Delete"
          onClick={() => {
            onDelete(params.id);
          }}
        />,
      ],
      flex: 1,
    },
  ];

  const deleteParty = () => {
    deletePartyById(selectedParty)
      .then((result) => {
        if (result.success) {
          showSuccessToastr('Party deleted successfully.');
        } else {
          showErrorToastr(result?.data?.message || result?.message || 'Something went wrong.');
        }
        setOpenDeletePartyDialog(false);
        setSelectedParty(null);
        setRefreshGrid(!refreshGrid);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setOpenDeletePartyDialog(false);
        setSelectedParty(null);
      });
  };

  useEffect(() => {
    const skip = currentPage === 0 ? 0 : currentPage * DT_PAGE_SIZE;
    setDataLoading(true);
    getParties({ skip })
      .then((result) => {
        if (result.success) {
          const { data } = result;
          setRows(data.parties);
          setTotalRecords(data.totalRecords);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        showErrorToastr(
          error?.response?.data?.message || error?.message || 'Something went wrong.'
        );
        setRows([]);
        setDataLoading(false);
      });
  }, [currentPage, refreshGrid]);

  return (
    <PrivateWrapper pageName="Parties">
      <Box display="flex" flexDirection="column">
        <div className={classes.filterLeft}>
          <div className={classes.moveRight}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.addNewBtn}
              onClick={() => {
                setOpenPartyFormDialog(true);
              }}
            >
              Add New
            </Button>
          </div>
        </div>
        <div style={{ height: '80vh', width: '100%' }}>
          <DataGrid
            loading={dataLoading}
            rows={rows}
            rowCount={totalRecords}
            columns={columns}
            pageSize={DT_PAGE_SIZE}
            page={currentPage}
            paginationMode="server"
            onPageChange={setCurrentPage}
          />
        </div>
        {openPartyFormDialog && (
          <PartyForm
            editId={selectedParty}
            title="Create Party"
            isOpen={openPartyFormDialog}
            onSave={() => {
              setOpenPartyFormDialog(false);
              setSelectedParty(null);
              setRefreshGrid(!refreshGrid);
            }}
            onClose={() => {
              setOpenPartyFormDialog(false);
              setSelectedParty(null);
            }}
          />
        )}
        {openDeletePartyDialog && (
          <ConfirmDialog
            title="Are you sure want to delete this customer?"
            isOpen={openDeletePartyDialog}
            onConfirm={deleteParty}
            onClose={() => {
              setOpenDeletePartyDialog(false);
              setSelectedParty(null);
            }}
          />
        )}
      </Box>
    </PrivateWrapper>
  );
};

export default Parties;
